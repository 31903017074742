.transactions-page {
  min-height: 100vh;
  display: flex;
  font-family: 'monument-regular', serif !important;
  flex-direction: column;
  background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC) ;
}

/* Table Styling */
.transaction-table-wrapper {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
}

.transaction-table {
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto;
  border-collapse: collapse;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.transaction-table th,
.transaction-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word;
  color: #4a4a4a; /* Light black text */
}

.transaction-table th {
  background-color: #f5f5f5;
  color: #333;
  font-weight: 600;
}

.transaction-table tbody tr:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.transaction-table td a {
  color: #1a73e8;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
}

.transaction-table td a:hover {
  text-decoration: underline;
}

/* Transaction Type Styling */
.transaction-type.payment {
  color: red;
  font-weight: 600;
}

.transaction-type.receive {
  color: green;
  font-weight: 600;
}

.transaction-type.transfer {
  color: orange;
  font-weight: 600;
}

.transaction-type.deposit {
  color: blue;
  font-weight: 600;
}

.transaction-type.withdraw {
  color: #4a4a4a; /* Light black text */
  font-weight: 600;
}

/* Responsive Table */
@media (max-width: 768px) {
  .transaction-table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .transaction-table {
    min-width: 600px; /* Ensures table doesn't shrink too much */
  }

  .transaction-table th,
  .transaction-table td {
    padding: 0.8rem;
    white-space: nowrap;
  }

  .transaction-table td {
    font-size: 0.85rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Details Button Styling */
.details-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
}

.details-button:hover {
  background-color: #1558b3;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #222222;
  padding: 1.5rem;
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  text-align: left;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content h2 {
  margin-top: 0;
  color: #333;
}

.modal-content p {
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #FFFFFF; /* Light black text */
}

.modal-content .wallet-address {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 90%;
  vertical-align: middle;
}

.modal-content .wallet-address:hover {
  text-decoration: underline;
}

.close-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.close-button:hover {
  background-color: #cc0000;
}

/* Animation for Modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Loading State Styling */
.loading {
  display: flex; 
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  text-align: center;
  font-size: 1.5rem;
  background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC);
  min-height: 100vh;
  font-family: 'monument-regular', serif !important;
}