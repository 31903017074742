.footer {
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #0D0D2B, #1A1A40, #0D0D2B);
    color: #E0E0E0;
    padding: 20px 40px;
    margin-top: 20px;
    font-family: 'Poppins', sans-serif, 'Inter', sans-serif;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 -4px 10px #A4B0EC; /* Add shadow above the footer */
  }
  
  .footer-logo img {
    height: 60px; /* Adjust the logo size */
  }
  
  .footer-separator {
    width: 2px;
    height: 100px; /* Adjust the height as needed */
    background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC) ;
    margin: 0 20px; /* Adjust the space between the columns */
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .footer-column {
    flex: 1;
    padding: 0 20px;
  }
  
  .footer-column h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 10px;
  }
  
  .footer-column ul li a {
    color: #E0E0E0;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-column ul li a:hover {
    color: #FFAA00; /* Brighter contrast for hover effect */
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  
    .footer-separator {
      width: 100%;
      height: 2px;
      margin: 20px 0;
    }
  
    .footer-content {
      flex-direction: column;
      width: 100%;
    }
  
    .footer-column {
      padding: 20px 0;
    }
  }