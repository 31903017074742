/* Reset default margins and paddings */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #0b0f19; /* Dark background color */
  background-image: radial-gradient(circle at 50% 50%, #0b0f19, #040c18);
  color: #D8C3FF;
  font-family: 'Poppins', sans-serif, 'Inter', sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Fixed Box */
.fixed-box {
  position: fixed;
  top: 180px;
  left: 20px;
  width: 180px;
  height: 100px;
  background: linear-gradient(black, black), linear-gradient(135deg, #D8C3FF, #756BFC);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 8px 16px rgba(141, 124, 124, 0.6);
  z-index: 999;
  border: 4px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  transform: translateY(-50%);
}

.fixed-box-text {
  font-size: 1.1rem;
  color: #D8C3FF;
  font-weight: 500;
  text-align: left;
  line-height: 1.2;
  padding-right: 10px;
  flex: 1;
}

.fixed-box-icon {
  font-size: 3rem;
  color: #D8C3FF;
}

@media (max-width: 768px) {
  .fixed-box {
    top: 200px;
    left: 10px;
    width: 200px;
    height: 100px;
  }

  .fixed-box-text {
    font-size: 1rem;
    padding-right: 5px;
  }

  .fixed-box-icon {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .fixed-box {
    top: 200px;
    left: 5px;
    width: 150px;
    height: 80px;
  }

  .fixed-box-text {
    font-size: 0.9rem;
    padding-right: 3px;
  }

  .fixed-box-icon {
    font-size: 2rem;
  }
}

/* Hero Content starts here */
.hero-section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../assets/images/blue-globe-glowing-atmosphere-wallpaper.jpg') no-repeat center center;
  background-size: 130% auto;
  background-position: center calc(-450px);
  position: relative;
  color: #D8C3FF;
}



@media(max-width:680px){
  .hero-section {
    align-items: center;
    background: url('../assets/images/blue-globe-glowing-atmosphere-wallpaper.jpg') no-repeat center center;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    min-height: 80vh;
    padding: 20px;
    text-align: center;
}

  .hero-section {
    display: block;
  }
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Increased opacity for stronger overlay */
  z-index: 1;
}

.content-container {
  text-align: center;
  padding: 20px;
  position: relative;
  z-index: 2;
}

.display-4 {
  font-size: 4rem;
  font-weight: 700;
  background: linear-gradient(90deg, #D8C3FF, #756BFC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); Stronger text shadow */
}

.typing-text {
  white-space: nowrap;
  overflow: hidden;
  border-right: .15em solid #756BFC;
  animation: typing 5s steps(32, end), blink-caret .75s step-end infinite;
  color: #D8C3FF; /* Updated text color */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow to typing text */
}

@media(max-width:680px){
  .typing-text {
    white-space: normal;
    animation: none !important;
  }
}

.register-button-content {
  margin-top: 20px;
  background: linear-gradient(135deg, #D8C3FF, #756BFC); /* Gradient similar to the screenshot */
  color: #0b0f19; /* Text color similar to the screenshot */
  padding: 12px 40px;
  cursor: pointer;
  border-radius: 30px; /* Rounded corners */
  font-size: 1.25rem;
  font-weight: 600;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 68, 255, 0.5), 0 4px 15px rgba(0, 180, 255, 0.5); /* Initial shadow */
  border: none; /* Removed border for a cleaner look */
}

.register-button-content:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 68, 255, 0.7), 0 6px 20px rgba(0, 180, 255, 0.7); /* Highlight effect on hover */
  background: linear-gradient(135deg, #756BFC, #D8C3FF); /* Slight gradient change on hover */
}


.cta-button {
  background: linear-gradient(135deg, #D8C3FF, #756BFC); /* Updated gradient */
  color: white;
  padding: 12px 40px;
  border-radius: 50px;
  font-size: 1.25rem;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 10px 20px rgba(216, 195, 255, 0.8), 0 10px 20px rgba(117, 107, 252, 0.6); /* Stronger box-shadow */
  border: 2px solid #D8C3FF;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 24px rgba(216, 195, 255, 1), 0 12px 24px rgba(117, 107, 252, 0.8);
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: #756BFC; }
}

@keyframes blink-caret-stop {
  0% { border-right-color: #756BFC; }
  100% { border-right-color: transparent; }
}

.typing-text {
  animation: 
    typing 5s steps(32, end), 
    blink-caret .75s step-end infinite, 
    blink-caret-stop 1s steps(1) 5s forwards;
}

/* Joined user section starts here */

.joined-users-section {
  width: 100%;
  background: linear-gradient(135deg, #003b99, #00b4ff); /* Gradient background to match the theme */
  padding: 10px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 30px;
  position: relative;
}

.joined-users-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.joined-users-title-container {
  z-index: 1; /* Ensure the text is above the scrolling user cards */
  background: linear-gradient(#0b0f19, #0b0f19), linear-gradient(135deg, #D8C3FF, #756BFC); /* Matching the fixed box theme */
  padding: 25px 20px;
  border-radius: 30px 0 0 30px; /* Rounded on the left side */
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
}

.joined-users-title {
  font-weight: bold;
  color: #ffffff; /* White text color for clear visibility */
}

.joined-users-container {
  display: flex;
  align-items: center;
  animation: scroll-left 20s linear infinite; /* Scroll the users from right to left */
  gap: 20px;
  margin-left: 200px; /* Add margin to accommodate the title box */
  width: calc(100% - 200px); /* Ensure proper width for scrolling */
}

.joined-user-card {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #0052cc, #00b4ff); /* Matching the gradient theme */
  border-radius: 20px;
  padding: 5px 15px;
  min-width: 250px; /* Ensure minimum width */
  flex-shrink: 0;
  color: #ffffff; /* White text color */
  transition: transform 0.3s ease;
}

@media(max-width:680px){
  .joined-users-section{
    margin-top: 20px;
    border: 2px solid #00A0EE;
    padding: 50px 0px 10px 0px;
  }
  .joined-user-card {
    display: inline-block;
  }

  .joined-users-title-container{
    text-align: center;
    border-radius: 0px;
    padding: 10px 20px;
    top: -28px;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
  }
  
}

.joined-user-card:hover {
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.user-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.user-details {
  font-weight: bold;
}

.user-name {
  color: #ffffff; /* White color to stand out */
  margin-right: 5px;
}

@keyframes scroll-left {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

/* New Section Content starts here */
.new-section {
  width: 100%;
  padding: 150px 20px;
  /* position: relative; */
  /* overflow: hidden; */
}

@media(max-width:680px){
  .new-section {
    padding: 60px 20px 20px 20px;
  }
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.new-section-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  position: relative;
  z-index: 1;
  align-items: center;
}

@media(max-width:680px){
  .new-section-container {
    display: inline;
  }
}

.text-content {
  max-width: 100%;
  text-align: left;
  color: #D8C3FF;
  font-weight: 700;
  margin-left: 50px;
}

.text-content h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #D8C3FF, #756BFC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media(max-width:680px){
  .text-content h2 {
    font-size: 30px;
  }
}

.text-content h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: white;
}

.text-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #c0c0c0;
  font-weight: 700;
}

.email-input {
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  border-image: linear-gradient(135deg, #756BFC, white) 1;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
}

.email-input input {
  padding: 10px;
  font-size: 1rem;
  border: none;
  flex: 1;
  outline: none;
  background-color: transparent;
  color: gray;
}

.email-input .try-button {
  padding: 9px 19px;
  font-size: 1rem;
  background-color: transparent;
  border: 2px solid transparent;
  border-image: linear-gradient(135deg, #756BFC, white) 1;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 0 5px 5px 0;
  outline: none;
  margin-left: -2px;
}

@media(max-width:680px){
  .email-input .try-button {
    margin-left: -80px;
  }
}

.email-input .try-button:hover {
  background-color: rgba(255, 0, 119, 0.1);
}

.email-input .try-button::after {
  content: ' ➔';
  margin-left: 5px;
}

.image-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-screen {
  width: 600px;
  height: auto;
  background: url('../assets/images/desktop.png') no-repeat center center;
  background-size: contain;
}

.desktop-gif {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}


/* User Section starts here */

.user-exclusive-section {
  width: 100%;
  padding: 100px 20px;
  position: relative;
  overflow: hidden;
  background-color: #0b0f19; /* Dark background color */
}

.user-exclusive-container {
  display: grid;
  grid-template-columns: 2fr 3fr; /* Two columns: boxes on the left, text on the right */
  gap: 20px; /* Gap between columns */
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.user-exclusive-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for the boxes */
  grid-template-rows: auto auto; /* Automatically adjust rows */
  gap: 20px; /* Space between boxes */
}

.user-exclusive-box {
  width: 242px; /* Figma size width */
  height: 313px; /* Figma size height */
  background: linear-gradient(159.29deg, #0A0D15 13.06%, rgba(0, 1, 2, 0) 106.26%);
  border: 1px solid;
  border-image-source: linear-gradient(167.9deg, #8A809B 10.43%, rgba(0, 0, 0, 0) 91.18%),
  linear-gradient(336.99deg, #9A9A9A -11.43%, rgba(0, 0, 0, 0) 40.39%);
  border-radius: 10px 0px 0px 0px;
  padding: 20px;
  text-align: center;
  color: #D8C3FF; /* Updated text color */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4); /* Background shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.user-exclusive-box:hover {
  transform: translateY(-5px); 
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.5);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

.icon-wrapper img {
  width: 98px; /* Adjust as necessary */
  height: 108px; /* Adjust as necessary */
  z-index: 1;
}

.icon-background {
  position: absolute;
  width: 165px; /* Figma size for shadow */
  height: 165px; /* Figma size for shadow */
  background: url('../assets/images/shadow.png') no-repeat center center;
  background-size: contain;
  z-index: 0;
}

.user-exclusive-box h3 {
  font-family: Poppins, sans-serif;
  font-size: 15px; /* Figma size for title text */
  font-weight: 600; /* Figma font weight for title text */
  line-height: 22.5px; /* Figma line height */
  text-align: center;
  color: #ffffff;
}

.user-exclusive-box p {
  font-family: Poppins, sans-serif;
  font-size: 10px; /* Figma size for content text */
  font-weight: 400; /* Figma font weight for content text */
  line-height: 15px; /* Figma line height */
  text-align: center;
  color: #c0c0c0;
  flex-grow: 1;
}

.user-exclusive-content {
  text-align: left;
  color: white;
  padding-left: 40px;
}

.user-exclusive-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  background: linear-gradient(90deg, #D8C3FF, #756BFC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); Stronger text shadow */
}

.user-exclusive-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #c0c0c0;
}

.user-exclusive-content .cta-button {
  background: linear-gradient(135deg, #D8C3FF, #756BFC); /* Gradient similar to the screenshot */
  color: #0b0f19; /* Text color similar to the screenshot */
  padding: 12px 40px;
  border-radius: 30px; /* Rounded corners */
  font-size: 1.25rem;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 68, 255, 0.5), 0 4px 15px rgba(0, 180, 255, 0.5); /* Initial shadow */
  border: none; /* Removed border for a cleaner look */
}

.user-exclusive-content .cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 68, 255, 0.7), 0 6px 20px rgba(0, 180, 255, 0.7); /* Highlight effect on hover */
  background: linear-gradient(135deg, #756BFC, #D8C3FF); /* Slight gradient change on hover */
}








/* User Exclusive Content ends here */

/* Secure Crowdfunding Section starts here */
.secure-crowdfunding-section {
  width: 100%;
  padding: 100px 20px;
  position: relative;
  overflow: hidden;
}

.secure-crowdfunding-section .container {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.secure-crowdfunding-section .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.secure-crowdfunding-section .image-wrapper {
  position: relative;
  max-width: 90%; /* Increase the max-width to make the image larger */
}

.secure-crowdfunding-section .image-wrapper img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  position: relative;
  z-index: 1; /* Ensure the image stays on top of the shadow */
}

.secure-crowdfunding-section .image-wrapper .shadow {
  position: absolute;
  top: 20px; /* Adjust to fine-tune shadow placement */
  left: 20px; /* Adjust to fine-tune shadow placement */
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/shadow.png');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0; /* Ensure the shadow stays behind the image */
  filter: blur(10px); /* Optional: add blur to the shadow for a softer effect */
}

@media (max-width: 768px) {
  .secure-crowdfunding-section .main-title {
    font-size: 2rem;
  }

  .secure-crowdfunding-section .subtitle {
    font-size: 1.25rem;
  }

  .secure-crowdfunding-section .image-wrapper {
    max-width: 100%; /* Adjust for smaller screens */
  }

  .secure-crowdfunding-section .image-wrapper .shadow {
    top: 10px; /* Adjust for smaller screens */
    left: 10px; /* Adjust for smaller screens */
  }
}


/* Secure Crowdfunding Section end here */


/* About Section */
.about-section {
  width: 100%;
  padding: 100px 20px;
  background-color: #0b0f19;
}

.about-container {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.main-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  background: -webkit-linear-gradient(0deg, #756BFC, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 50px;
  color: #b0b0b0;
}

.about-boxes-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap; /* Ensures the boxes wrap on smaller screens */
}

.about-box {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 40px 30px;
  text-align: left;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1; /* Ensures the boxes take equal space */
  min-width: 300px; /* Ensures a minimum width */
  max-width: 32%; /* Prevents boxes from being too wide */
}

.about-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
}

.about-box-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 30px;
}

.check-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.about-box h3 {
  font-size: 1.75rem;
  margin-bottom: 20px;
  color: #e0e0e0;
}

.about-box p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #c0c0c0;
}

.about-box ul {
  list-style: none;
  padding: 0;
}

.about-box li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #d0d0d0;
}

.about-learn-more-button {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.about-learn-more-button:hover {
  background-color: #2c276d;
}

@media (max-width: 768px) {
  .about-boxes-container {
    flex-direction: column;
    gap: 20px;
  }

  .about-box {
    max-width: 100%;
  }
}



/* Global Section starts here */
.global-presence-section {
  width: 100%;
  padding: 100px 20px;
  position: relative;
  overflow: hidden;
  background: #0b0f19; /* Dark background color */
  background-image: radial-gradient(circle at 50% 50%, #0b0f19, #040c18); /* Match background to other sections */
  color: #ffffff;
}

.global-presence-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.global-presence-subtitle {
  font-size: 1.25rem;
  margin-bottom: 50px;
  color: #b0b0b0; /* Consistent subtitle color */
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }
}

.feature-box {
  background-color: rgba(0, 0, 0, 0.1); /* Consistent box background color */
  border-radius: 10px;
  padding: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  position: relative; /* Position relative to apply pseudo-elements */
}

.feature-box:hover {
  background-color: rgba(216, 195, 255, 0.2); /* Shiny hover effect */
  box-shadow: 0 4px 20px rgba(216, 195, 255, 0.5); /* Enhance shiny effect with shadow */
  transform: translateY(-5px); /* Slight lift on hover */
}

.feature-box::before,
.feature-box::after {
  content: '';
  position: absolute;
  border-radius: 10px; /* Match the box border radius */
  pointer-events: none;
}

.feature-box::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-image-source: linear-gradient(45deg, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.2) 50%);
  border-image-slice: 1;
}

.feature-box::after {
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 4px solid transparent;
  border-image-source: linear-gradient(45deg, #D8C3FF 50%, #756BFC 50%);
  border-image-slice: 1;
}

.feature-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  background: -webkit-linear-gradient(0deg, #756BFC, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block; /* Ensure the line matches the text width */
}

.feature-title::after {
  content: '';
  display: block;
  width: 100%; /* Match the line width to the text */
  height: 2px;
  background-color: #756BFC;
  margin: 10px 0; /* Adjust spacing as needed */
}

.feature-description {
  font-size: 1rem;
  color: #c0c0c0;
}

.feature-icon {
  width: 100px; /* Ensure the icons are large enough */
  height: 100px; /* Ensure the icons are large enough */
  margin-bottom: 20px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .feature-icon {
    width: 70px; /* Ensure the icons are large enough */
    height: 70px; /* Ensure the icons are large enough */
    object-fit: contain;
  }

  .content-text {
    margin-top: 40px; /* Adjust margin for mobile */
  }

  .display-4 {
    font-size: 35px; /* Adjust font size for mobile */
  }

  .join-text{
    font-size: 22px;
  }

  .register-button {
    font-size: 1rem; /* Adjust button font size for mobile */
    padding: 8px 16px; /* Adjust button padding for mobile */
  }

  .joined-users-outer{
    padding-left: 20px;
    padding-right: 20px;
  }
}


  .new-section-container {
    flex-direction: column;
    align-items: center;
  }

  .text-content {
    text-align: center;
    padding: 0 20px 20px;
    max-width: 80%;
  }


  @media(max-width:896px){
    .text-content {
      max-width: 100%;
      margin-left: -5px;
    }

    .image-content {
     display: none;
    }
  
  }

  .image-content {
    padding: 20px 0;
  }

  .user-exclusive-section {
    padding: 70px 20px;
  }

  .user-exclusive-boxes-container {
    flex-direction: column;
    align-items: center;
  }

  .box {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  .left-box,
  .right-box {
    margin-top: 20px;
  }

/* About boxes starts here */
.about-section {
  width: 100%;
  padding: 100px 20px;
  background-color: #0a0a0a; /* Dark background color */
}

.about-section h3{
  color: #ffffff;
}

.about-container {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.main-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  background: -webkit-linear-gradient(0deg, #756BFC, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 50px;
  color: #b0b0b0;
}

.about-boxes-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap; /* Ensures the boxes wrap on smaller screens */
}

.about-box {
  background: rgba(20, 20, 20, 0.9); /* Slightly transparent dark background */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border */
  border-radius: 15px;
  padding: 40px 30px;
  text-align: left;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  flex: 1; /* Ensures the boxes take equal space */
  min-width: 300px; /* Ensures a minimum width */
  max-width: 32%; /* Prevents boxes from being too wide */
  position: relative;
}


.about-box:hover {
  background-color: rgba(216, 195, 255, 0.2); /* Shiny hover effect */
  box-shadow: 0 4px 20px rgba(216, 195, 255, 0.5); /* Enhance shiny effect with shadow */
  transform: translateY(-5px); /* Slight lift on hover */
}

.about-box-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1; /* Ensure the icon stays above the shadow */
}

@media(max-width:680px){
  .about-box-icon {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
}

.about-box-icon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 165px; /* Figma size for shadow */
  height: 165px; /* Figma size for shadow */
  background: url('../assets/images/shadow.png') no-repeat center center;
  background-size: contain;
  transform: translate(-50%, -50%); /* Center the shadow behind the icon */
  z-index: -1; /* Ensure the shadow stays behind the icon */
  filter: blur(4px); /* Optional: Add blur for a softer shadow */
}




.check-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.about-box h3 {
  font-size: 1.75rem;
  margin-bottom: 20px;
  color: #e0e0e0;
  position: relative;
}

.about-box h3::after {
  content: '';
  display: block;
  width: 100%; /* Match the line width to the text */
  height: 2px;
  background-color: #756BFC;
  margin: 10px 0; /* Adjust spacing as needed */
}

.about-box p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #c0c0c0;
}

.about-box ul {
  list-style: none;
  padding: 0;
}

.about-box li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #d0d0d0;
}

.about-learn-more-button {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 20px rgba(216, 195, 255, 0.5); /* Enhance shiny effect with shadow */
}

.about-learn-more-button:hover {
  background-color: #756BFC;
  color: white;
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(216, 195, 255, 0.5);
}

@media (max-width: 768px) {
  .about-boxes-container {
    flex-direction: column;
    gap: 20px;
  }

  .about-box {
    max-width: 100%;
  }
}



/* FAQ Section */
#FAQ {
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  width: 100%; /* Ensure full width of FAQ section */
  background-color: #0a0a0a;
}

#FAQ h2 {
  font-family: 'circularstd-black', serif;
  font-size: 4vw;
  color: #ffffff;
  margin-bottom: 2vw;
  text-align: center;
}

/* FAQ Card Styling */
#FAQ .card {
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
  border-radius: 1vw;
  background: linear-gradient(145deg, #0e0f1f, #1c1f35); /* Gradient background */
  margin-bottom: 0.8vw;
  width: 100%; /* Keep the card's width consistent */
  transition: max-height 0.5s ease, padding 0.5s ease;
  overflow: hidden; /* Ensure content is hidden when collapsed */
}

#FAQ .card .card-header {
  background-color: #001025;
  border: none;
  padding: 1vw 2vw;
  border-radius: 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#FAQ .card button {
  font-family: 'poppins-semibold', serif;
  font-size: 1.4vw;
  background-color: transparent; /* Make button transparent to show the card header */
  color: #ffffff;
  text-align: left;
  padding: 0;
  width: 100%; /* Ensure button spans the full width */
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#FAQ .card .card-body {
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 2.5vw;
  font-size: 1vw;
  color: #AAAADC;
  max-height: 0; /* Initially collapsed */
  transition: max-height 0.5s ease, padding 0.5s ease;
}

#FAQ .card .collapse.show .card-body {
  max-height: 500px; /* Large enough value to handle expanded content */
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
}

#FAQ .faq-arrow {
  font-size: 1.2vw;
  transition: transform 0.3s ease;
}

#FAQ .faq-arrow.open {
  transform: rotate(180deg); /* Rotate arrow when expanded */
}

/* Preventing the section from shrinking */
#FAQ .collapse {
  overflow: hidden; /* Keep content inside the container */
}

#FAQ .collapse.show {
  overflow: visible; /* Allow expanded content to show fully */
}

/* View More Button */
.view-more-container {
  text-align: center;
  margin-top: 3vw;
}

.view-more-button {
  font-family: 'poppins-semibold', serif;
  font-size: 1.3vw;
  color: white;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 20px rgba(216, 195, 255, 0.5); /* Enhance shiny effect with shadow */
  text-decoration: none; /* Remove underline */
}

.view-more-button:hover {
  background-color: #756BFC;
  color: white;
  transform: translateY(-5px); /* Button rises up slightly */
  box-shadow: 0 4px 20px rgba(216, 195, 255, 0.5); /* Keep the shadow on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #FAQ {
    padding-left: 8vw;
    padding-right: 8vw;
  }

  #FAQ h2 {
    font-size: 6vw;
  }

  #FAQ .card button {
    font-size: 4vw;
  }

  #FAQ .faq-arrow {
    font-size: 4vw;
  }

  #FAQ .card .card-body {
    font-size: 3.5vw;
  }

  .view-more-button {
    font-size: 4vw;
  }
}

