/* Ensure full height to keep the page balanced */
.profile-page {
  display: flex;
  flex-direction: column;
  font-family: 'monument-regular', serif !important;
  min-height: 100vh;
  background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC) ;
}

/* Full-width container with proper alignment */
.theme-background {
  background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC) ;
  color: white;
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Make the card wider and centered */
.profile-card {
  background-color: #2b2b2e;
  border-radius: 12px;
  padding: 3rem;
  width: 90%; /* Take 90% of the screen width */
  max-width: 800px; /* Limit max-width */
  margin: 0 auto;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-top: 50px;
}

/* Profile image adjustments */
.profile-image {
  margin-bottom: 1.5rem;
}

.profile-image img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid #4CAF50;
  background-color: white;
}

/* Profile details layout */
.profile-details {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two-column layout for wider screens */
  gap: 1rem;
}

.profile-field {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Label and input styling */
.profile-field label {
  font-weight: bold;
  font-size: 1rem;
}

.profile-field input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid #4CAF50;
  background-color: #3c3c3e;
  color: white;
  font-size: 1rem;
}

/* Password section styling */
.password-section {
  margin-top: 2rem;
}

.password-section hr {
  margin-bottom: 1.5rem;
  border-color: rgba(255, 255, 255, 0.1);
}

/* Buttons */
.change-password-btn {
  margin-top: 1rem;
  background-color: #4CAF50;
  border: none;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: white;
  border-radius: 6px;
}

.profile-field button {
  background-color: #f0ad4e;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

/* Verified and not verified icons */
.verified {
  color: #4CAF50;
}

.not-verified {
  color: #F44336;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-card {
    padding: 1.5rem;
    grid-template-columns: 1fr; /* Single column on mobile */
    max-width: 100%;
  }

  .profile-field input {
    font-size: 0.9rem;
  }

  .change-password-btn {
    font-size: 0.9rem;
  }

  .profile-field button {
    font-size: 0.9rem;
  }
}

/* Modal styling */
.change-password-modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: fixed !important;
  z-index: 1000 !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.modal-content {
  background-color: #2b2b2e !important;
  margin: auto !important;
  padding: 20px !important;
  border: 1px solid #888 !important;
  width: 80% !important;
  max-width: 500px !important;
  border-radius: 8px !important;
  color: white !important;
}

.modal-heading {
  color: white !important;
}

.close {
  color: #aaa !important;
  float: right !important;
  font-size: 28px !important;
  font-weight: bold !important;
}

.close:hover,
.close:focus {
  color: white !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.modal-field {
  margin-bottom: 15px !important;
}

.modal-field label {
  display: block !important;
  margin-bottom: 5px !important;
}

.modal-field input {
  width: 100% !important;
  padding: 8px !important;
  box-sizing: border-box !important;
  background-color: #3c3c3e !important;
  border: 1px solid #4CAF50 !important;
  color: white !important;
}

.password-input {
  display: flex;
  align-items: center;
}

.password-input input {
  flex: 1;
}

.password-input button {
  margin-left: 10px;
  padding: 8px;
  background-color: #4CAF50;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.password-input button:hover {
  background-color: #45a049;
}

.password-input i {
  margin-left: 10px;
  cursor: pointer;
  color: #4CAF50;
}

.password-input i:hover {
  color: #45a049;
}

.error {
  color: red !important;
  margin-bottom: 10px !important;
}

.success {
  color: green !important;
  margin-bottom: 10px !important;
}