 @media (max-width: 650px) {          /*---------------MOBILEs-----------------*/

    .dashnav{
        padding-left: 20px;
        padding-right: 20px;
    }

     .connectbtn{
        width: 100%;
        margin-top: 20px;
     }

  .logoutbtn{
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .userdetails {
    margin-top: 20px;
}

.userdiv{
    margin-bottom: 20px;
}

.joineddiv .col-md-3{
    margin-bottom: 20px;
}

.joineddiv .col-md-6{
    margin-bottom: 20px;
}

.statscard{
    margin-bottom: 10px;
}
    
 }




  @media (max-width: 900px)  /*-----------TABLETS--------------*/
  and (min-width: 700px){
   
  



  }







  @media (max-width: 1300px)  /*-----------13/10 inch laptop--------------*/
  and (min-width: 900px){
   
  



  }






  @media (max-width: 1930px)  /*-----------23/24 inch laptop--------------*/
  and (min-width: 1820px){

    .cont{
        max-width: 1700px; 
        margin: 0 auto;
      }
      

  }  