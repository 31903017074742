/* src/App.css */
body {
    background-color: #23203a;
    color: #23203a;
    font-family: 'monument-regular', serif !important;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.001em;
  }
  @font-face {
    font-family: monument-regular;
    src: url(../../assets/fonts/MonumentExtended-Regular.otf);
  }
  
  @font-face {
    font-family: monument-ultrabold;
    src: url(../../assets/fonts/MonumentExtended-Ultrabold.otf);
  }
  .dashboard {
    background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC) ;
    width: 100%;
  }
  
  .navbar {
    background: #23203a;
  }
  
  .nav-link {
    color: white;
  }
  
  .nav-link:hover {
    color: #ddd;
  }
  
  /* Add other styles here */
  