.footer {
    display: flex;
    align-items: center;
    background-color: #0b0f19; /* Dark background color */
    color: white;
    padding: 20px 40px;
    font-family: 'Poppins', sans-serif, 'Inter', sans-serif;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.5); /* Add shadow above the footer */
  }
  
  .footer-logo img {
    height: 60px; /* Adjust the logo size */
  }
  
  .footer-separator {
    width: 2px;
    height: 100px; /* Adjust the height as needed */
    background: linear-gradient(135deg, #ff0077, #7900ff); /* Gradient color */
    margin: 0 20px; /* Adjust the space between the columns */
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .footer-column {
    flex: 1;
    padding: 0 20px;
  }
  
  .footer-column h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 10px;
  }
  
  .footer-column ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-column ul li a:hover {
    color: #ff0077; /* Adjust hover color */
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  
    .footer-separator {
      width: 100%;
      height: 2px;
      margin: 20px 0;
    }
  
    .footer-content {
      flex-direction: column;
      width: 100%;
    }
  
    .footer-column {
      padding: 20px 0;
    }
  }
  