/* Background Overlay */
.deposit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Modal Content */
  .deposit-modal-content {
    background-color: #111;
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
    position: relative;
    max-width: 400px;
    width: 100%;
    min-height: 250px;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    animation: scaleUp 0.4s ease-in-out;
  }
  
  /* Checkmark Animation */
  .checkmark-animation {
    width: 80px;
    height: 80px;
    margin: 0 auto 15px;
    animation: bounce 1.2s ease-in-out infinite;
  }
  
  .checkmark-animation img {
    width: 100%;
    height: 100%;
  }
  
  /* Title */
  .deposit-title {
    font-size: 24px;
    color: white !important;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  
  .deposit-modal-content p {
    font-size: 18px;
    color: #ddd;
  }
  
  /* Confetti Effect */
  .confetti-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
  }
  
  .confetti {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #ff0;
    opacity: 0.8;
    border-radius: 50%;
    animation: confetti-fall linear infinite;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes scaleUp {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  /* Confetti Animation */
  @keyframes confetti-fall {
    0% {
      transform: translateY(-50px) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(500px) rotate(720deg);
      opacity: 0;
    }
  }
  