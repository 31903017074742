/* Reset default margins and paddings */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(135deg, #1e1e1e, #4b0082); Purple gradient background */
  color: #23203a;
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}


.appInner {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo {
  width: 150px; /* Adjust the size as needed */
  margin-bottom: 0px;
  height: auto;
}


.header-logo{
  margin-right: 60px;
}