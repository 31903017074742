/* FAQ Card Styling */
#FAQ .card {
    border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
    border-radius: 1vw;
    background: linear-gradient(145deg, #0e0f1f, #1c1f35); /* Gradient background */
    margin-bottom: 0.8vw;
    width: 100%; /* Keep the card's width consistent */
    transition: max-height 0.5s ease, padding 0.5s ease;
    overflow: hidden; /* Ensure content is hidden when collapsed */
}

#FAQ .card .card-header {
    background-color: #001025;
    border: none;
    padding: 1vw 2vw;
    border-radius: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Allows absolute positioning of the arrow */
}

#FAQ .card button {
    font-family: 'poppins-semibold', serif;
    font-size: 1.4vw;
    background-color: transparent; /* Make button transparent to show the card header */
    color: #ffffff;
    text-align: left;
    padding: 0;
    width: 100%; /* Ensure button spans the full width */
    border: none;
    display: flex;
    justify-content: flex-start; /* Align text to the left */
    align-items: center;
}

#FAQ .faq-arrow {
    font-size: 1.2vw;
    transition: transform 0.3s ease;
    position: absolute;
    right: 1.5vw; /* Place the arrow on the far right */
}

#FAQ .faq-arrow.open {
    transform: rotate(180deg); /* Rotate arrow when expanded */
}

#FAQ .card .card-body {
    padding-top: 1vw;
    padding-bottom: 1vw;
    padding-left: 2.5vw;
    font-size: 1vw;
    color: #AAAADC;
    max-height: 0; /* Initially collapsed */
    transition: max-height 0.5s ease, padding 0.5s ease;
}

#FAQ .card .collapse.show .card-body {
    max-height: 500px; /* Large enough value to handle expanded content */
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
}

/* Preventing the section from shrinking */
#FAQ .collapse {
    overflow: hidden; /* Keep content inside the container */
}

#FAQ .collapse.show {
    overflow: visible; /* Allow expanded content to show fully */
}

/* View More Button */
.view-more-container {
    text-align: center;
    margin-top: 3vw;
}

.view-more-button {
    font-family: 'poppins-semibold', serif;
    font-size: 1.3vw;
    color: white;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 25px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 20px rgba(216, 195, 255, 0.5); /* Enhance shiny effect with shadow */
    text-decoration: none; /* Remove underline */
}

.view-more-button:hover {
    background-color: #756BFC;
    color: white;
    transform: translateY(-5px); /* Button rises up slightly */
    box-shadow: 0 4px 20px rgba(216, 195, 255, 0.5); /* Keep the shadow on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    #FAQ {
        padding-left: 8vw;
        padding-right: 8vw;
    }

    #FAQ h2 {
        font-size: 6vw;
    }

    #FAQ .card button {
        font-size: 4vw;
    }

    #FAQ .faq-arrow {
        font-size: 4vw;
    }

    #FAQ .card .card-body {
        font-size: 3.5vw;
    }

    .view-more-button {
        font-size: 4vw;
    }
}