/* Modal Common Styles */
.modal-content {
  font-family: 'monument-regular', serif !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-content p {
  margin: 0;
  color: #000000;
}

.modal-content button {
  background-color: #741eb7; /* Theme color */
  color: #FFFFFF;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  width: 100%; /* This 100% width will now only apply to buttons inside the modal */
}
.modal-content button:disabled {
  background-color: #888; /* Gray background when disabled */
  cursor: not-allowed;
  opacity: 0.6; /* Slightly faded to indicate it's disabled */
}
.modal-content button:hover:not(:disabled) {
  background-color: #9250d3; /* Lighter theme color */
}


/* Close button styling */
.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #FF0000; /* Red background */
  border: none;
  font-size: 1.5rem;
  color: #FFFFFF; /* White cross button */
  cursor: pointer;
  border-radius: 50%; /* Circular shape */
  width: 40px; /* Adjust size */
  height: 40px; /* Adjust size */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for better appearance */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.modal-close-btn:hover {
  background-color: #C70000; /* Darker red on hover */
  transform: scale(1.1); /* Slight zoom on hover */
}

.modal-close-btn:focus {
  outline: none;
}

/* Icon inside the button */
.modal-close-btn i {
  font-size: 1.2rem;
  color: #FFFFFF; /* Ensure the cross stays white */
}

/* Select Box Styling */
select {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #9250d3;
  background-color: #2a2d46;
  color: #ffffff;
  font-size: 1rem;
  appearance: none; /* Remove default dropdown arrow */
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}


/* Enhancing Select Box */
select:hover {
  border-color: #741eb7; /* Darker border on hover */
}

/* Dropdown Option Styling */
option {
  background-color: #2a2d46;
  color: #ffffff;
}

/* Specific Styling for Modals */
.deposit-modal,
.withdraw-modal,
.transfer-modal {
  font-family: 'monument-regular', serif !important;
  background-color: #1C2438;
  border-radius: 16px;
  padding: 20px;
  width: 450px; /* Wider box for better appearance */
  max-width: 90%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.deposit-modal h2,
.withdraw-modal h2,
.transfer-modal h2 {
  color: #FFFFFF;
  font-size: 1.6rem;
  margin-bottom: 20px;
}

/* Token Selection with Icons */
.token-select {
  display: flex;
  align-items: center;
  gap: 10px;
}

.token-select select {
  flex-grow: 1;
}

.token-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.token-icon.bnb {
  background-image: url('../../../assets/images/Usdt.webp'); /* Replace with actual path */
  background-size: cover;
}

.token-icon.usdt {
  background-image: url('../../../assets/images/Usdt.webp'); /* Replace with actual path */
  background-size: cover;
}

.timer {
  color: #FFA500; /* Theme color for timer */
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
}

/* Modal Overlay */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

/* Custom Modal Styling */
.ReactModal__Content {
  outline: none; /* Remove outline for better styling */
}

/* DepositModals QR CODE button */
.network-info {
  background-color: #333;
  color: #d3d3d3;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 6px;
  margin-bottom: 15px;
  font-weight: bold;
}

.qr-code-section {
  display: flex;
  justify-content: center; /* Centers the QR code horizontally */
  margin: 20px 0; /* Adds some space around the QR code */
}

.address-display {
  display: flex;
  align-items: center;
  background-color: #333; /* Dark background color */
  border-radius: 6px;
  padding: 4px 8px;
  color: #d3d3d3; /* Light gray text color */
  font-size: 14px;
  max-width: fit-content;
}

.wallet-address {
  margin-right: 4px; /* Small gap between address and icon */
  font-family: monospace;
  color: #d3d3d3; /* Ensures text color is consistent */
}

.copy-icon {
  width: 20px; /* Small icon size */
  height: 20px;
  filter: invert(100%) brightness(150%); /* Matches icon color with text color */
}

.note {
  background-color: #cc0000;
  font-size: 12px;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 20px;
  text-align: center;
  max-width: 100%;
}

.confirm-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
}

.modal-content .confirm-button:disabled {
  background-color: #888; /* Gray background when disabled */
  cursor: not-allowed;
  opacity: 0.6; /* Slightly faded to indicate it's disabled */
}
.modal-content .confirm-button:hover:not(:disabled) {
  background-color: #9250d3; /* Lighter theme color */
}
.confirm-button:hover {
  background-color: #45a049;
}




/* ================================  Transfer Modal Container  ============================= */


.transfer-modal {
  background-color: #393939;
  border-radius: 16px;
  padding: 20px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  color: white;
}

/* Modal Title */
.modal-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Section Container */
.transfer-section {
  margin-bottom: 20px;
}

/* Section Label */
.section-label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
  color: #FFFFFF;
}

/* From Section */
.from-box {
  background-color: #C6B3FE;
  border-radius: 12px;
  padding: 15px;
}

.from-details {
  margin-bottom: 15px;
}

.from-username {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}

.from-address {
  font-size: 0.8rem;
  color: #000000;
}

.amount-box {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.amount-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #9250d3;
  border-radius: 8px;
  background-color: #333;
  color: white;
  font-size: 1rem;
  margin-bottom: 8px;
  outline: none;
}

.balance-text {
  font-size: 0.9rem;
  color: #ffffff;
}

.max-text {
  color: #48ff00;
  font-weight: bold;
  cursor: pointer;
}

.max-text:hover {
  color: #9250d3;
  text-decoration: underline;
}

/* To Section */
.search-box {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #C6B3FE;
  border-radius: 12px;
  padding: 10px;
  width: 100%;
}


.to-box {
  background-color: #2B3557;
  border-radius: 12px;
  padding: 15px;
}


.search-input {
  flex-grow: 1;
  border: none;
  padding: 10px 40px 10px 10px; /* Extra padding-right for the icon */
  border-radius: 8px;
  font-size: 1rem;
  background-color: #222222;
  color: white;
  outline: none; /* Remove default outline */
  width: 100%;
}

.search-icon {
  position: absolute;
  right: 20px; /* Align icon to the right */
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
}

.search-icon:hover {
  color: #9250d3; /* Add a hover effect for the search icon */
}

/* Dropdown Styling */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #2B3557;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #333;
}

.dropdown-item-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Action Buttons */
.transfer-action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  
}

.cancel-button {
  background-color: transparent;
  color: #888;
  padding: 10px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s;
  margin-right: 10px;
}

.cancel-button:hover {
  color: #ffffff;
}

.continue-button {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.continue-button:hover {
  background: linear-gradient(to right, #0056b3, #003d80);
}

/* General Styles */
.balance-text {
  margin-top: 5px;
  color: #ffffff;
  font-size: 0.9rem;
}

.wallet-selection {
  position: relative;
}

.wallet-dropdown {
  width: 100%;
  padding: 8px;
  padding-right: 40px;
  /* font-size: 14px; */
  line-height: 1.5;
}

.wallet-dropdown option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
}

.amount-input {
  width: 100%;
  padding: 12px;
  background-color: #222222;
  color: white;
  border: 1px solid #741eb7;
  border-radius: 8px;
  outline: none;
  font-size: 1rem;
}

.amount-input:focus {
  border-color: #9250d3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .transfer-modal {
    width: 90%;
    padding: 15px;
  }

  .modal-title {
    font-size: 1.5rem;
  }

  .amount-input {
    font-size: 0.9rem;
  }
}

.search-results {
  margin-top: 10px;
  background-color: #2B3557;
  border-radius: 8px;
  padding: 10px;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.search-result-item {
  padding: 8px;
  cursor: pointer;
  color: white;
  border-bottom: 1px solid #444;
}

.search-result-item:hover {
  background-color: #444;
}

.loading-text {
  margin-top: 10px;
  color: #888;
  font-size: 0.9rem;
}


/* Withdrawal Modal */

.withdrawal-from-box {
  color: white; /* Set text color to white */
  font-size: 1rem; /* Adjust font size if necessary */
  background-color: transparent; /* Optional: Set background to transparent */
  padding: 10px; /* Add some padding if needed */
  border-radius: 8px; /* Optional: Add rounded corners */
}