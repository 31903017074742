/* General Styles */
html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif; 
}

/* Register Page Layout */
.register-page {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #1b1f3a;
  overflow: hidden;
}

/* Image Section */
.register-image-section {
  flex: 1;
  background: linear-gradient(135deg, #2a2f6d, #1b1f3a);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  color: #fff;
  text-align: center;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
}

.register-image-section h1 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.register-image-section p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #d1d5db;
}

.register-image {
  width: 50%;
  max-width: 250px;
  margin-bottom: 10px;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.4));
}

/* Form Section */
.register-form-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  background-color: #1b1f3a;
  background: linear-gradient(135deg, #1b1f3a, #2a2f6d);
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
  color: #333;
}

.register-form-section h2 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #9ea2ff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #ffffff;
}

.input-group input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #333a6b;
  border-radius: 5px;
  background-color: #2a2f6d;
  color: #fff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.6);
}

/* Register Button */
.register-button {
  width: 100%;
  padding: 12px;
  background: linear-gradient(135deg, #2a2f6d, #1b1f3a);
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.error-message {
  color: red;
  font-size: 12px;
}
.register-button:hover {
  background: linear-gradient(135deg, #373c8d, #2a2f6d);
  transform: translateY(-2px);
}

/* Terms and Conditions Text */
.terms {
  margin-top: 15px;
  color: #9ea2ff;
  margin-bottom: 10px;
}

.terms input {
  margin-right: 10px; /* Adjust the value to control the gap size */
}

.terms a {
  color: #756BFC;
  text-decoration: none;
}

.terms a:hover {
  text-decoration: underline;
}


/* Login Link */
.login-link {
  margin-top: 10px;
  font-size: 0.875rem;
  color: #9ea2ff;
}

.login-link a {
  color: #756BFC;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

/* Wallet Connect Section */
.wallet-connect-section {
  margin-top: 20px;
  text-align: center;
}

.wallet-connect-section p {
  margin-bottom: 10px;
  color: #d1d5db;
}

.wallet-connect-button {
  width: 100%;
  padding: 12px;
  background: linear-gradient(135deg, #2a2f6d, #1b1f3a);
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.wallet-connect-button:hover {
  background: linear-gradient(135deg, #373c8d, #2a2f6d);
  transform: translateY(-2px);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .register-page {
    flex-direction: column;
  }

  .register-image-section {
    display: none; /* Hide the image section on mobile */
  }

  .register-form-section {
    padding: 20px;
    width: 100%; /* Ensure the form section takes full width */
  }

  .register-form-section h2 {
    font-size: 1.5rem;
  }

  .input-group {
    margin-bottom: 12px;
  }

  .input-group input {
    padding: 10px;
  }

  .register-button {
    padding: 12px;
  }

  .wallet-connect-button {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .register-page {
    flex-direction: column;
  }
  
  .register-form-section {
    padding: 15px;
  }
  
  .register-form-section h2 {
    font-size: 1.25rem;
  }
  
  .input-group input {
    padding: 8px;
    font-size: 0.875rem;
  }
  
  .register-button,
  .wallet-connect-button {
    padding: 10px;
    font-size: 0.875rem;
  }
  
  .terms {
    font-size: 0.8rem;
  }
  
  .login-link {
    font-size: 0.75rem;
  }
  
  .wallet-connect-section p {
    font-size: 0.875rem;
  }
}