html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif; 
}

.login-page {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #1b1f3a;
  flex-direction: row;
}

.web3-section {
  flex: 1;
  background: linear-gradient(135deg, #2a2f6d, #1b1f3a);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  color: #fff;
  text-align: center;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
}

.web3-section h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.web3-section img {
  width: 60%;
  max-width: 300px;
  margin-bottom: 20px;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.4));
}

.web3-button {
  padding: 15px 30px;
  background: linear-gradient(135deg, #373c8d, #2a2f6d);
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.web3-button:hover {
  background: linear-gradient(135deg, #474c9d, #373c8d);
  transform: translateY(-2px);
}

.web3-section p {
  font-size: 0.9rem;
  margin-top: 10px;
  color: #d1d5db;
}

.login-form-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  background-color: #1b1f3a;
  background: linear-gradient(135deg, #1b1f3a, #2a2f6d);
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
}

.login-form-section h2 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #9ea2ff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.login-form-section p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #bfc4ff;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #d1d5db;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #333a6b;
  border-radius: 5px;
  background-color: #2a2f6d;
  color: #fff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.6);
}

.login-button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(135deg, #2a2f6d, #1b1f3a);
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.login-button:hover {
  background: linear-gradient(135deg, #373c8d, #2a2f6d);
  transform: translateY(-2px);
}

.login-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.login-options a {
  color: #9ea2ff;
  text-decoration: none;
}

.register-now-section {
  text-align: right;
  margin-top: 10px;
}

.register-now-section span {
  color: rgba(255, 255, 255, 0.6); /* Light white color */
}

.register-link {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.register-link:hover {
  text-decoration: underline;
}

.wallet-connect-section {
  text-align: center;
  margin-top: 40px;
}

.wallet-connect-section h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #9ea2ff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.wallet-connect-button {
  padding: 15px 30px;
  background: linear-gradient(135deg, #2a2f6d, #1b1f3a);
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.wallet-connect-button:hover {
  background: linear-gradient(135deg, #373c8d, #2a2f6d);
  transform: translateY(-2px);
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .login-page {
    flex-direction: column;
  }

  .web3-section {
    padding: 20px;
  }

  .web3-section img {
    width: 80%;
    max-width: 250px;
  }

  .web3-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  .login-form-section {
    padding: 20px;
  }

  .login-form-section h2 {
    font-size: 1.5rem;
  }

  .login-form-section p {
    font-size: 0.9rem;
  }

  .input-group input {
    padding: 8px;
  }

  .login-button {
    padding: 12px;
    font-size: 0.9rem;
  }

  .wallet-connect-button {
    padding: 12px 20px;
    font-size: 0.9rem;
  }

  .wallet-connect-section h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .web3-section img {
    width: 90%;
    max-width: 200px;
  }

  .web3-button {
    font-size: 0.8rem;
    padding: 8px 16px;
  }

  .login-form-section h2 {
    font-size: 1.25rem;
  }

  .login-button {
    padding: 10px;
    font-size: 0.8rem;
  }

  .wallet-connect-button {
    padding: 10px 16px;
    font-size: 0.8rem;
  }

  .wallet-connect-section h3 {
    font-size: 1rem;
  }
}