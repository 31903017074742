/*---------------

COLOR : #74c209;

--------------*/


.swal2-container {
	z-index: 10000 !important;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

*:focus {
	outline: 0 !important;
}


@font-face {
	font-family: monument-regular;
	src: url(../../assets/fonts/MonumentExtended-Regular.otf);
}

@font-face {
	font-family: monument-ultrabold;
	src: url(../../assets/fonts/MonumentExtended-Ultrabold.otf);
}



html,
body {
	scroll-behavior: smooth;
	margin: 0 !important;
	padding: 0 !important;
	width: 100%;
}

body {
	overflow-x: hidden;
	/* background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC) ; */

}

background-dashboard {
	background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC) !important;
}

a {
	text-decoration: none !important;
	cursor: pointer;
}

.cont {
	font-family: 'monument-regular', serif !important;
	max-width: 1520px;
	/* width: 100% !important; */
	margin: 0 auto;

}

@media (min-width: 1400px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 100%;
	}
}

@media (max-width: 650px) {

	/*---------------MOBILEs-----------------*/
	.cont {
		max-width: 100%;
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}

}


.dashnav {
	background: transparent !important;
	padding-left: 40px;
	padding-right: 40px;
	border-bottom: 1px solid #FFFFFF;
}

.dashnav .logo {
	width: 100px;
	margin-right: 30px;
}

.dashnav .nav-link {
	font-family: 'monument-regular', serif !important;
	color: #000000;
	background: transparent !important;
	font-size: 14px;
	margin-right: 25px;
	padding: 8px 12px;
	/* Add padding for better spacing */
	border-radius: 5px;
	/* Optional: Rounds the corners */
	transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.dashnav .nav-link:hover {
	background-color: #A4B0EC;
	/* Change this to your desired hover background color */
	color: #000000;
	/* Change this to the text color you want on hover */
}

.dashnav .socialbtn {
	background: transparent;
	border: none;
}

.dashnav .socialbtn img {
	width: 28px;
	height: 28px;
	object-fit: contain;
	margin-right: 20px;
}

.activate-message {
	color: red;
	font-size: 18px;
	margin-top: 8px;
  }

.balance-container {
	display: flex;
	justify-content: center;
	margin-top: 10px;

	align-items: center;
  }
  
  .custom-dropdown {
	position: relative;
	display: flex;
	align-items: center;
	/* gap: 8px; */
	/* border: 1px solid #ccc; */
	/* padding: 5px 10px; */
	border-radius: 5px;
	cursor: pointer;
	background-color: transparent; /* Keep dropdown visible */
  }
  
  .balance-dropdown {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	border: none;
	font-size: 14px;
	cursor: pointer;
	outline: none;
	text-align: center;
	width: 100px; /* Set a fixed width to prevent wrapping */
	white-space: nowrap; /* Prevents text from breaking into two lines */
	overflow: hidden; /* Hides any overflowing text */
	text-overflow: ellipsis; /* Adds "..." if the text is too long */
	padding: 0px;
  }

  .balance-dropdown option {
	font-family: 'monument-regular', serif !important;

  }
  
  /* Custom dropdown arrow */
  .custom-dropdown::after {
	content: "⏷"; /* Unicode down arrow */
	position: absolute;
	right: 10px;
	font-size: 14px;
	color: #FFF; /* Set the icon color */
	pointer-events: none; /* Prevent clicks */
  }
  .balance-value {
	font-weight: bold;
	color: #FFF;
  }
.dashnav .connectbtn {
	font-family: 'monument-regular', serif !important;
	background: #403CB5;
	color: #FFFFFF;
	font-size: 16px;
	border: none;
	padding: 5px 10px;
	border-radius: 10px;
	margin-right: 30px;
}

.dashnav .logoutbtn {
	font-family: 'monument-regular', serif !important;
	background: #AA0406;
	border: none;
	border-radius: 10px;
	color: #FFFFFF;
	font-size: 16px;
	padding: 5px 10px;
}


.userdetails {
	margin-top: 50px;
}

.userdiv {
	background: linear-gradient(to bottom, #545B87, #6B6F8C);
	border-radius: 10px;
	padding: 40px 25px;
}

.userdiv-inner {
	border: 2px solid #777DAF;
	width: 100px;
	height: 100px;
	margin: 0 auto;
	border-radius: 50%;
	position: relative;
}

.userdiv img {
	position: absolute;
	top: 45%;
	left: 55%;
	transform: translate(-50%, -50%);
	width: 70px;
	height: 70px;
	object-fit: contain;
}

.userdiv h2 {
	color: #FFFFFF;
	font-size: 16px;
	margin-top: 15px;
}

.userdiv h2 span {
	font-size: 20px;
}

.userdiv .levels {
	font-size: 14px;
	width: 100%;
	text-align: left;
	padding: 5px 20px;
	border: none;
	margin-top: 15px;
	background: linear-gradient(to right, #544FD0, #0F0D4E);
	color: #ffffff;
	border-radius: 8px;
	border: 1px solid #777DAF;

	/* Flexbox to align text and icon properly */
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.copy-text {
	font-size: 14px;
	cursor: pointer;
	color: #C6B3FE;
	margin-left: 10px;
  }
  
  .copy-text:hover {
	opacity: 0.7;
  }

.referraldiv {
	background: #101130;
	padding: 20px 30px;
	border-radius: 20px;
}

.referraldiv h2 {
	font-size: 14px;
	color: #8C87ED;
}

.referraldiv input {
	width: 100%;
	background: #2F2E3E;
	border: 1px solid #777DAF;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 14px;
	height: 30px;
	color: #FFFFFF;
}

.referraldiv input::placeholder {
	color: #FFFFFF;
	font-size: 14px;
}

.referraldiv button {
	width: 100%;
	height: 30px;
	border: none;
	color: #FFFFFF;
	font-size: 14px;
	background: #7963EC;
}

.joineddiv {
	background: #101130;
	padding: 20px 0px 10px 0px;
	margin-top: 10px;
}

.joineddiv h2 {
	font-size: 14px;
	color: #8C87ED;
}

.joineddiv h3 {
	font-size: 16px;
	color: #FFFFFF;
}


.statsdiv {
	margin-top: 10px;
}

.statscard {
	background: linear-gradient(to right, #686B8B, #74768D);
	padding: 25px 0px;
}

.statscard img {
	height: 60px;
	width: auto;
	object-fit: contain;
}

.statscard h2 {
	font-size: 14px;
	margin-top: 10px;
	color: #FFFFFF;
}


.inputcard {
	margin-top: 20px;
	background: linear-gradient(to bottom, #6D718C, #76788F);
	border-radius: 10px;
	position: relative;
	padding: 20px 10px 10px 10px;

}

.inputcard .mainicon {
	height: 40px;
	width: auto;
	object-fit: contain;
}

.inputcard input {
	width: 100%;
	color: #000000;
	margin-top: 15px;
	height: 30px;
}

.inputcard .subbtn {
	font-size: 14px;
	padding: 5px 20px 5px 25px;
	border: none;
	margin-top: 8px;
	background: linear-gradient(to right, #544FD0, #0F0D4E);
	color: #ffffff;
	border-radius: 8px;
	border: 1px solid #777DAF;
}


.accordion-item {
	margin-top: 20px;
}

.accordion-header {
	background: #0D1031;
}

.accordion-button {
	background: #0D1031 !important;
	color: #FFFFFF !important;
	padding-left: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.5 6l6 6 6-6h-12z'/%3E%3C/svg%3E");
}

.accordion-item {
	background: transparent !important;
	border: none;
}

.accordion-body {
	padding: 0px;
	margin-top: 20px;
	background: transparent !important;
}


.zonecard {
	background: #0D102F;
	border-radius: 10px;
	position: relative;
	min-height: 250px;
	margin-bottom: 20px;
}

/* .zonecard {
	position: relative;
	overflow: hidden;
  } */

.zone-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	/* Semi-transparent overlay */
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.zonecard:hover .zone-overlay {
	opacity: 1;
}

.activate-btn {
	background: linear-gradient(135deg, #3a0ca3, #7209b7);
	/* Dark Purple Gaming Gradient */
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	padding: 12px 24px;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	text-transform: uppercase;
	box-shadow: 0 4px 12px rgba(114, 9, 183, 0.5);
}

.activate-btn:hover {
	filter: brightness(1.4) contrast(1.3);
	/* Higher contrast & brightness */
	transform: scale(1.08);
	/* Slight pop effect */
	box-shadow: 0 6px 18px rgba(114, 9, 183, 0.8);
	font-weight: 900;
	/* Bolder text */
}


.zonecard .dot {
  position: absolute;
  background: #ffffff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: background 0.3s ease;
}

/* 🎯 Apply primary app color when the user is present */
.zonecard .dot.filled {
  background: rgba(114, 9, 183, 0.8);
  border: 2px solid #ffffff; /* White border for contrast */
}
.tooltip {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    padding: 6px 10px;
    border-radius: 5px;
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

.dot:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
/* Maintain positions */
.zonecard .middle-dot { width: 40px; height: 40px; top: 25%; left: 50%; }
.zonecard .left-middle-dot { top: 45%; left: 30%; }
.zonecard .right-middle-dot { top: 45%; right: 20%; }
.zonecard .left-left-dot { top: 70%; left: 20%; }
.zonecard .left-right-dot { top: 70%; left: 40%; }
.zonecard .right-left-dot { top: 70%; right: 30%; }
.zonecard .right-right-dot { top: 70%; right: 10%; }

.zonecard .zonetext {
	color: #FFFFFF;
	font-size: 14px;
	position: absolute;
	top: 10px;
	left: 20px;
}

.zonecard .dollar {
	position: absolute;
	font-size: 14px;
	top: 10px;
	right: 20px;
	color: #FFFFFF;
}

.zonecard .dollar img {
	width: 25px;
}

.zonecard .people {
	position: absolute;
	font-size: 14px;
	bottom: 10px;
	left: 20px;
	color: #FFFFFF;
}

.zonecard .people img {
	width: 25px;
}

.zonecard .refresh {
	position: absolute;
	font-size: 14px;
	bottom: 10px;
	right: 20px;
	color: #FFFFFF;
}

.zonecard .refresh img {
	width: 25px;
}