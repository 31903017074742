/* Center the modal on the screen */
.activation-modal {
    background-color: #1C2438; /* Theme dark background */
    border-radius: 16px;
    padding: 20px;
    width: 650px;
    max-width: 90%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    color: white;
    position: fixed; /* Position fixed for centering */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for element's own dimensions */
    z-index: 1000; /* Ensure it appears above other elements */
  }
  
  /* Modal Overlay to darken the background */
  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Transparent black overlay */
    z-index: 999; /* Just below the modal itself */
  }
  
  /* Modal Title */
  .activation-modal h2 {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff; /* White title color */
  }
  
  /* Modal Content Section */
  .activation-modal .modal-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .activation-modal .modal-content p {
    margin: 0;
    font-size: 1rem;
    color: #FFFFFF; /* White text for details */
    text-align: center;
  }
  
  .activation-modal .modal-content p .highlight {
    font-weight: bold;
    color: #48ff00; /* Highlighted amount in green */
  }
  
  /* Button Styles */
  .activation-modal .confirm-button {
    background: linear-gradient(to right, #741eb7, #9250d3); /* Gradient button */
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: background 0.3s, transform 0.2s;
  }
  
  .activation-modal .confirm-button:hover {
    background: linear-gradient(to right, #9250d3, #741eb7); /* Lighter gradient on hover */
    transform: scale(1.05); /* Slight zoom effect */
  }
  
  .activation-modal .confirm-button:disabled {
    background: #888; /* Gray background when disabled */
    cursor: not-allowed;
  }
  
  /* Timer Section */
  .activation-modal .timer {
    margin-top: 15px;
    font-size: 1rem;
    color: #888;
    text-align: center;
  }
  /* Modal Table Styling */
.activation-modal .modal-content {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.activation-modal .modal-content td {
  padding: 10px;
  text-align: left;
  color: white;
}

  /* Close Button */
  .activation-modal .modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: #ffffff;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .activation-modal .modal-close-btn:hover {
    color: #ff6666; /* Red color on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .activation-modal {
      width: 90%;
      padding: 15px;
    }
  
    .activation-modal h2 {
      font-size: 1.5rem;
    }
  
    .activation-modal .confirm-button {
      font-size: 0.9rem;
    }
  
    .activation-modal .modal-content p {
      font-size: 0.9rem;
    }
  }