/* Background Overlay */
.withdrawal-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999 !important;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Modal Content */
  .withdrawal-modal-content {
    background-color: #111; /* Dark modal */
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
    position: relative;
    max-width: 400px;
    width: 100%;
    min-height: 250px;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    animation: scaleUp 0.4s ease-in-out;
  }
  
  /* Success Animation */
  .success-animation {
    width: 80px;
    height: 80px;
    margin: 0 auto 15px auto;
    animation: bounce 1.2s ease-in-out infinite;
  }
  
  .success-animation img {
    width: 100%;
    height: 100%;
  }
  
  /* Modal Header */
  .withdrawal-heading {
    font-size: 24px;
    color: white !important;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  
  .withdrawal-modal-content p {
    font-size: 18px;
    color: #ddd;
  }
  
  /* Keyframes for Animations */
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes scaleUp {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  /* Confetti Effect */
  .confetti-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
  }
  
  .confetti {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #ff0;
    opacity: 0.8;
    border-radius: 50%;
    animation: confetti-fall linear infinite;
  }
  
  .confetti:nth-child(1) { left: 5%; animation-duration: 3s; animation-delay: 0s; }
  .confetti:nth-child(2) { left: 15%; background-color: #ff4081; animation-duration: 2.5s; animation-delay: 0.2s; }
  .confetti:nth-child(3) { left: 25%; background-color: #00bcd4; animation-duration: 3.2s; animation-delay: 0.4s; }
  .confetti:nth-child(4) { left: 35%; background-color: #ff9800; animation-duration: 2.8s; animation-delay: 0.6s; }
  .confetti:nth-child(5) { left: 45%; background-color: #4caf50; animation-duration: 3.5s; animation-delay: 0.8s; }
  .confetti:nth-child(6) { left: 55%; background-color: #9c27b0; animation-duration: 3s; animation-delay: 1s; }
  .confetti:nth-child(7) { left: 65%; background-color: #ff5722; animation-duration: 3.2s; animation-delay: 1.2s; }
  .confetti:nth-child(8) { left: 75%; background-color: #8bc34a; animation-duration: 2.7s; animation-delay: 1.4s; }
  .confetti:nth-child(9) { left: 85%; background-color: #03a9f4; animation-duration: 3.3s; animation-delay: 1.6s; }
  .confetti:nth-child(10) { left: 95%; background-color: #e91e63; animation-duration: 2.9s; animation-delay: 1.8s; }
  
  @keyframes confetti-fall {
    0% {
      transform: translateY(-50px) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(500px) rotate(720deg);
      opacity: 0;
    }
  }
  