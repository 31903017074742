/* Notifications Page Styling */
.notifications-page {
    min-height: 100vh;
    display: flex;
    font-family: 'monument-regular', serif !important;
    flex-direction: column;
    background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC) ;
  }
  
  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #828282;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  /* Table Styling */


.notification-table-wrapper {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
}

  .notification-table {
    width: 100%;
    max-width: 1000px;
    margin: 2rem auto;
    border-collapse: collapse;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .notification-table th,
  .notification-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
    color: #787676; /* Light black text */
  }
  
  .notification-table th {
    background-color: #f5f5f5;
    color: #333;
    font-weight: 600;
  }
  
  .notification-table tbody tr:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .status {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-weight: 600;
  }
  
  .status.read {
    background-color: #d4edda;
    color: #155724;
  }
  
  .status.unread {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  /* Loading State Styling */
  .loading {
    display: flex; 
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    text-align: center;
    font-size: 1.5rem;
    background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC);
    min-height: 100vh;
    font-family: 'monument-regular', serif !important;
  }
  
  /* Responsive Table */
  @media (max-width: 768px) {


  .notification-table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .notification-table {
    min-width: 600px; /* Ensures table doesn't shrink too much */
  }
  
    .notification-table th,
    .notification-table td {
      padding: 0.8rem;
      white-space: nowrap;
    }
  }