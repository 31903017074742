/* Team Page Styling */
.team-page {
  min-height: 100vh;
  display: flex;
  font-family: 'monument-regular', serif !important;
  flex-direction: column;
  background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC) ;
}

.team-main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
}

/* Zone Toggle Buttons */
.team-zone-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.team-zone-button {
  background-color: #e0e0e0;
  border: none;
  color: #333;
  padding: 0.8rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.team-zone-button.active {
  background-color: #1a73e8;
  color: white;
}

.team-zone-button:hover {
  background-color: #d6d6d6;
  color: #000;
}

/* Team Table Styling */
.team-table {
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto;
  border-collapse: collapse;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
}

.team-table-wrapper {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
}

.team-table th,
.team-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word;
  color: #4a4a4a;
  white-space: nowrap; /* Prevent text wrapping */
}

.team-table th {
  background-color: #f5f5f5;
  color: #333;
  font-weight: 600;
}

.team-table tbody tr:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.team-table td a {
  color: #1a73e8;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
}

.team-table td a:hover {
  text-decoration: underline;
}

/* Loading State Styling */
.team-loading {
  display: flex; 
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  text-align: center;
  font-size: 1.5rem;
  background: linear-gradient(to right, #A4B0EC, #F3F3FD, #A4B0EC);
  min-height: 100vh;
  font-family: 'monument-regular', serif !important;
}


/* Responsive Design */
@media (max-width: 768px) {
  .team-table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .team-table {
    min-width: 600px; /* Ensures table doesn't shrink too much */
  }

  .team-main-content {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  .team-zone-toggle {
    flex-direction: column;
    align-items: center;
  }

  .team-zone-button {
    width: 90%;
    margin-bottom: 0.5rem;
  }
}
