.support-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 800px;
  font-family: 'monument-regular', serif !important;
  margin: 0 auto;
  margin-top: 30px;
  background: linear-gradient(to right, #0D0D2B, #1A1A40, #0D0D2B); /* Gradient background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff; /* White text for contrast */
  font-size: 13px;
}

.support-page h1 {
  text-align: center;
  font-size: 26px;
  color: #fff; /* White text */
  margin-bottom: 20px;
}

.submit-request {
  margin-bottom: 30px;
  background-color: rgba(26, 26, 26, 0.8); /* Semi-transparent dark gray for contrast */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.submit-request h2 {
    color: #fff; /* White text */
    font-size: 23px;
    margin-bottom: 15px;
  }

.submit-request input {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 13px;
  border: 1px solid #555; /* Darker border */
  border-radius: 4px;
  background-color: #444; /* Darker background */
  color: #fff; /* White text */
}

.submit-request textarea {
  width: 100%;
  height: 120px;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 13px;
  border: 1px solid #555; /* Darker border */
  border-radius: 4px;
  background-color: #444; /* Darker background */
  color: #fff; /* White text */
  resize: none;
}

.submit-request button {
  padding: 14px 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(90deg, #007bff, #0056b3);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
  align-self: flex-end;
}

.submit-request button:hover {
  background: linear-gradient(90deg, #0056b3, #004494);
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 123, 255, 0.4);
}

.submit-request button:disabled {
  background: #7a7a7a;
  cursor: not-allowed;
  box-shadow: none;
}

.tickets-list {
  background-color: rgba(26, 26, 26, 0.8); /* Semi-transparent dark gray for contrast */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tickets-list h2 {
  color: #fff; /* White text */
  font-size: 23px;
  margin-bottom: 15px;
}

.tickets-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.tickets-table th, .tickets-table td {
  border: 1px solid #555;
  padding: 12px;
  text-align: left;
}

.tickets-table th {
  background-color: #222;
  color: #fff;
  font-weight: bold;
}

.tickets-table td {
  background-color: #333;
  color: #fff;
  position: relative;
}

.tickets-list li {
  border: 1px solid #555; /* Darker border */
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #444; /* Darker background */
  color: #fff; /* White text */
  font-size: 12px;
}

.tickets-list li p {
  margin: 5px 0;
}

.tickets-list li p strong {
  color: #ccc; /* Light gray for emphasis */
}

.toggle-message-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 12px;
  padding-left: 5px;
}

.toggle-message-btn:hover {
  text-decoration: underline;
}

.expanded-description {
  background-color: #222;
  color: #fff;
  padding: 10px;
  border-top: 1px solid #555;
  display: none; /* Initially hidden */
}

/* New styles for the expand/collapse icon */
.tickets-table .expand-icon {
  cursor: pointer;
  font-size: 16px; /* Increased size */
  color: #007bff; /* Match the table's theme */
  margin-left: 10px;
  text-align: center; /* Center align */
  vertical-align: middle; /* Vertical align */
}

.tickets-table .expand-icon:hover {
  opacity: 0.7; /* Change opacity on hover */
}