body {
    background-color: #121212;
    color: #ffffff;
  }
  
  .text-light {
    color: #ffffff !important;
  }
  
  .text-muted {
    color: #6c757d !important;
  }
  
  .table-dark {
    background-color: #343a40;
  }
  
  .card {
    border: none;
  }
  
  .card-body {
    background-color: #212529;
  }
  