/* ✅ Navbar Layout */
.dashnav {
  background: transparent !important;
  padding: 10px 40px;
  border-bottom: 1px solid #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.notification-badge {
  /* background-color: red; */
  color: red;
  font-size: 24px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 50%;
  margin-left: 1%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1; /* Ensures proper vertical alignment */
  vertical-align: middle; /* Aligns the badge with the text */
}
/* ✅ Fix Logo Taking Full Width */
.logo-container {
  display: flex;
  align-items: center;
}

.dashnav .logo {
  width: 100px;
}

/* ✅ Navbar Links */
.nav-links {
  display: flex;
  gap: 20px;
}

.dashnav .nav-link {
  color: #ffffff;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.dashnav .nav-link:hover {
  background-color: #A4B0EC;
  color: #000000;
}

/* ✅ Social & Action Buttons */
.dashnav .socialbtn {
  background: transparent;
  border: none;
}

.dashnav .socialbtn img {
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 10px;
}

.dashnav .connectbtn {
  background: #403CB5;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 20px;
}

.dashnav .logoutbtn {
  background: #AA0406;
  border: none;
  border-radius: 10px;
  color: #FFFFFF;
  font-size: 16px;
  padding: 5px 10px;
}

/* ✅ Collapsible Menu Button */
.navbar-toggler {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.nav-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap; /* Ensure buttons don't stack */
  justify-content: center;
}

/* ✅ Mobile: Collapsible Navbar */
@media (max-width: 768px) {
  .dashnav {
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
  }

  .nav-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .navbar-toggler {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background: transparent !important;
    padding: 10px 0;
    text-align: center;
  }

  .nav-links.show, .nav-actions.show {
    display: flex;
  }

  .nav-actions {
    flex-wrap: nowrap;  /* Prevent wrapping */
    overflow-x: auto;   /* Allow horizontal scrolling if needed */
    justify-content: space-around;
    width: 100%;
  }
  
  .socialbtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Adjust size */
    height: 40px;
  }

  .nav-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
}
