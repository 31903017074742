/* Reset default margins and paddings */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #0b0f19; /* Dark background color */
  background-image: radial-gradient(circle at 50% 50%, #0b0f19, #040c18);
  color: white;
  font-family: 'Poppins', sans-serif, 'Inter', sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.hero-container {
  width: 100%;
  background: transparent; /* Transparent background to match other sections */
  padding: 20px 30px 10px 30px; /* Increased padding for better spacing */
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2); /* Subtle border for separation */
}

.logo-container {
  flex: 1;
}

.logo {
  height: 50px; /* Adjust the height as needed */
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.nav-menu {
  flex: 2;
  display: flex;
  justify-content: center; /* Align to the right */
  padding-right: 30px; /* Reduced padding to better match design */
}

.nav-menu.open {
  display: block; /* Display block when menu is open */
}

.nav-box {
  display: flex;
  gap: 20px;
  background: rgba(255, 255, 255, 0.1); /* Lighter background for contrast */
  padding: 10px 20px;
  border-radius: 10px;
  backdrop-filter: blur(10px); /* Add blur effect for a modern look */
}

.nav-item {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-item:hover {
  background-color: #998BFD; /* Light blue background on hover */
  color: #e0e0e0; /* Slightly lighter text color for hover effect */
}

.action-buttons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Button Styles */
.button {
  background: linear-gradient(135deg, #D8C3FF, #756BFC); /* Updated gradient */
  color: white;
  padding: 10px 16px; /* Reduced padding for a slimmer appearance */
  cursor: pointer;
  border-radius: 10px; /* Slightly rounded corners */
  font-size: 0.9rem; /* Slightly smaller font size */
  font-weight: 500;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  border: none; /* Remove border */
}

.button:hover {
  transform: translateY(-2px); /* Slight lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 68, 255, 0.8), 0 4px 8px rgba(0, 180, 255, 0.6); /* Subtle shadow on hover */
}

/* Specific styles for the Register button */
.register {
  background: linear-gradient(135deg, #D8C3FF, #756BFC); /* Updated gradient */
}

/* Specific styles for the Connect Wallet button */
.connect-wallet {
  background: linear-gradient(135deg, #D8C3FF, #756BFC); /* Updated gradient */
  color: white;
  padding: 8px 16px; /* Reduced padding */
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  font-size: 0.9rem; /* Slightly smaller font size */
  font-weight: 500;
  border: none;
}

.connect-wallet:hover {
  transform: translateY(-2px); /* Slight lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 68, 255, 0.8), 0 4px 8px rgba(0, 180, 255, 0.6); /* Subtle shadow on hover */
}



/* Media query for mobile devices */

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start */
    padding: 10px;
  }

  .logo-container {
    width: 92%;
    display: flex;
    justify-content: space-between; /* Space out logo and hamburger */
    align-items: center; /* Center items vertically */
    margin-bottom: 10px; /* Space between logo and menu */
  } 

  .hamburger-menu {
    
    width: 8%;
    display: flex;
    justify-content: space-between; /* Space out logo and hamburger */
    align-items: center; /* Center items vertically */
    margin-bottom: 10px; /* Space between logo and menu */
  }

  .nav-menu {
    display: none; /* Hide the menu initially */
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
    padding: 0; /* Remove padding on small screens */
  }

  .nav-menu.open {
    display: flex; /* Show the menu when open */
  }

  .nav-box {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 100%; /* Full width for mobile */
  }

  .action-buttons {
    display: none; /* Hide action buttons initially */
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
  }

  .action-buttons.open {
    display: flex; /* Show action buttons when menu is open */
  }

  .button {
    width: 100%;
    text-align: center;
  }
}

/* Styles specific to the modal card */
.modal-card {
  width: auto;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
  position: fixed; /* Center the modal */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#loginModal h2, #registerModal h2 {
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: 2vw;
  color: black;
}

#loginModal h3 {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 0.75vw;
  color: black;
}

.modal-card a.fp {
  width: 100%;
  text-align: center; /* Center align the text */
  font-size: 0.8em; /* Smaller font size */
  color: #5881d0;
  margin-bottom: 10px;
}

.or {
  font-size: 0.9em; /* Smaller font size */
  text-align: center; /* Center align the text */
  width: 100%;
  margin: 10px 0; /* Add margin for spacing */
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form input {
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  border: 1px solid #dddddd;
  color: #a0a6a3;
  font-family: "Roboto Mono", sans-serif;
  box-shadow: 1px 5px 9px rgba(211, 211, 211, 0.7);
}

.form input.email {
  margin-bottom: 20px;
}

.form input.pass {
  margin-bottom: 20px;
}

.login_btn {
  font-size: 18px;
  color: white;
  border-radius: 10px;
  border: none;
  background-color: #003a74;
  width: 100%;
  padding: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  background: linear-gradient(-90deg, #003a74, #006ad5);
  box-shadow: 1px 5px 9px rgba(211, 211, 211, 0.9);
}

.register_btn {
  font-size: 18px;
  color: white;
  border-radius: 10px;
  border: none;
  background-color: #003a74;
  width: 100%;
  padding: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  background: linear-gradient(-90deg, #003a74, #006ad5);
  box-shadow: 1px 5px 9px rgba(211, 211, 211, 0.9);
}

.verify_email {
  font-size: 18px;
  color: white;
  border-radius: 10px;
  border: none;
  background-color: #003a74;
  width: 100%;
  padding: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  background: linear-gradient(-90deg, #003a74, #006ad5);
  box-shadow: 1px 5px 9px rgba(211, 211, 211, 0.9);
}

.footer_card {
  display: flex;
  width: 100%;
  justify-content: center;
}

.footer_card p {
  margin-right: 10px;
}

.footer_card a {
  color: #5881d0;
}

/* Style for the close button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #aaa;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Media queries for responsiveness */
@media (max-width: 680px) {
  .modal-content {
    width: 90%; /* Full width on small screens */
    margin: 20% auto; /* Adjust top margin for small screens */
  }

  .login_btn {
    font-size: 16px; /* Slightly smaller font size on small screens */
  }

  .form input {
    font-size: 14px; /* Slightly smaller input text on small screens */
  }

  .login-btn-div .register{
    margin-left: 6px !important;
    }

    .login-btn-div{
      display: inline-flex;
    }
}




.login-btn-div .register{
margin-left: 10px;
}

  